import { NButton, NConfigProvider, NSpace, dateEnGB, enUS } from 'naive-ui';
import { type VNode } from 'vue';
import IconMenu from '@/assets/icons/menu.svg';
import Clouds from '@/assets/images/clouds.svg?raw';
import Logo from '@/assets/logos/fun-academy-campus-white.svg?component';
import LocaleSwitcher from '@/components/LocaleSwitcher/LocaleSwitcher';
import SidebarMenu from '@/components/SidebarMenu/SidebarMenu';
import { NuxtLink } from '#components';

/**
 * Default layout compromising of a header, sidebar menu,
 * main content area and footer.
 * @package fun-academy-campus
 */
export default defineNuxtComponent({
  name: 'DefaultLayout',
  setup() {
    const { hasFetchedContent } = useContentStore();
    const { hasFetchedProgress } = useDataStore();
    const localePath = useLocalePath();
    const route = useRoute();
    const { toggleSidebarMenu } = useSidebarMenu();
    const { themeOverrides } = useTheme();
    const showClouds = computed<boolean>(
      () =>
        (String(route.name).includes('task') &&
          hasFetchedContent.value &&
          hasFetchedProgress.value) ||
        (!String(route.name).includes('task') && hasFetchedContent.value)
    );
    return {
      localePath,
      showClouds,
      themeOverrides,
      toggleSidebarMenu,
    };
  },
  render(): VNode {
    return (
      <NConfigProvider
        dateLocale={dateEnGB}
        locale={enUS}
        themeOverrides={this.themeOverrides}
      >
        <header
          class="fixed left-0 right-0 top-0 z-1 h-24 w-full flex items-start justify-between bg-cover bg-left-bottom bg-no-repeat p-4"
          id="app-header"
        >
          <div class="left">
            <h1 class="m-0 flex-1 items-center">
              <NuxtLink to={this.localePath({ name: 'index' })}>
                <Logo aria-label={this.$t('appName')} class="w-24" />
              </NuxtLink>
            </h1>
          </div>
          <div class="right">
            <NSpace align="start" size={0}>
              <LocaleSwitcher />
              <NButton
                onClick={this.toggleSidebarMenu}
                round
                style="--n-border: 1px solid transparent"
              >
                <IconMenu />
              </NButton>
            </NSpace>
          </div>
        </header>
        <SidebarMenu />
        <main
          class="grid flex-1 place-items-center px-6 pb-18 pt-28"
          id="app-content"
        >
          {this.$slots.default?.() ?? null}
        </main>
        <footer
          class="fixed bottom-0 left-0 right-0 z-1 flex justify-between bg-white px-4 py-2 text-xs shadow-gray-900 shadow-lg"
          id="app-footer"
        >
          <p>
            <NuxtLink href="https://funacademycampus.fi/" target="_blank">
              {this.$t('copyright', { year: new Date().getFullYear() })}
            </NuxtLink>
          </p>
          <p>
            <NuxtLink href="https://www.airobots.fi/" target="_blank">
              {this.$t('poweredBy')}
            </NuxtLink>
          </p>
        </footer>
        {this.showClouds && (
          <div class="max-h-40 object-contain animated animated-fade-in animated-slow">
            <div class="w-full" v-html={Clouds} />
          </div>
        )}
      </NConfigProvider>
    );
  },
});
